// components/SoonPage.js
import React from 'react';
import '../components/SoonPage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

const SoonPage = () => {

  return (

    <div className="wrapper">

    <svg>
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="name"/>
            <feColorMatrix in="name" mode="matrix" values="1 0 0 0 0
                                                          0 1 0 0 0
                                                          0 0 1 0 0
                                                          0 0 0 15 -10" result="b"/>

            <feBlend in="SourceGraphic" in2="b" />
          </filter>
        </defs>
      </svg>

      <div className='container'>
        <p className="text">Mamen.id</p>
        <div className="drop"></div>
        {/* <h1 className="title">GOODBYE MAMEN.ID WEBSITE</h1>
        <p className="description">
          RIP 2016 - 2023
        </p> */}
        <p className="coming-soon"> </p>
      </div>
     
      <div className="latest-episode">
          <h2 className="title-h2">Latest Podcast:</h2>
          <iframe 
          title ="podcast mamen" 
          src="https://open.spotify.com/embed/episode/4rCox7gwS0Ru2g4aWqkoEf?utm_source=generator&theme=0" 
          allowfullscreen="" 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy">
          </iframe>
      </div>
     
      <div className="social-icons">
        <a className="twitter" href="https://twitter.com/mamenofficial" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a className="instagram" href="https://instagram.com/mamen.ld" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a className="youtube" href="https://www.youtube.com/@MamenID" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a className="tiktok" href="https://www.tiktok.com/@mamen.ld" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTiktok} />
        </a>
      </div>
    </div>
  );
};

export default SoonPage;