// ArticlePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ArticlePage = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Fetch local JSON data using axios
    axios
      .get('/wordpress-data.json')
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Conditional rendering when articles data is not available yet
  if (articles.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Articles</h1>
      <ul>
        {articles.map((article) => (
          <li key={article.ID}>
            <h2>{article.title.rendered}</h2>
            <p>{article.content.rendered}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticlePage;