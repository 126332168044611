// App.js
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ArticlePage from './pages/ArticlePage';
import SoonPage from './pages/SoonPage';

const App = () => {
  return (
    <Router>
      <Switch>
        {/* Route for the default homepage */}
        <Route exact path="/" component={SoonPage} />

        {/* Route for the articles page */}
        <Route path="/article/:id" component={ArticlePage} />
      </Switch>
    </Router>
  );
};

export default App;